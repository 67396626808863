import React, { useEffect, useContext, useState } from "react";
import TitleBar from "../TitleBar";
import useCustomerSearch from "../Hooks/useCustomerSearch";
import useFetchCustomerName from "../Hooks/useFetchCustomerName";
import {
  Autocomplete,
  TextField,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import Cookies from "js-cookie";
import { Delete, Create } from "@mui/icons-material";
import axios from "axios";
import EventPopups from "../Reusable/EventPopups";
import { Print, Email, Download } from "@mui/icons-material";

import { useNavigate } from "react-router-dom";
import formatDate from "../../custom/FormatDate";
import LoaderButton from "../Reusable/LoaderButton";
import Contacts from "../CommonComponents/Contacts";
import ServiceLocations from "../CommonComponents/ServiceLocations";
import { DataContext } from "../../context/AppData";
import CircularProgress from "@mui/material/CircularProgress";
import useDeleteFile from "../Hooks/useDeleteFile";
import useFetchContactEmail from "../Hooks/useFetchContactEmail";
import BackButton from "../Reusable/BackButton";
import FileUploadButton from "../Reusable/FileUploadButton";
import PrintButton from "../Reusable/PrintButton";
import { baseUrl } from "../../apiConfig";
import CustomerAutocomplete from "../Reusable/CustomerAutocomplete";
import AddStoreLocation from "../CommonComponents/AddStoreLocation";
import SignInput from "../CommonComponents/SignInput";
import imageCompresser from "../../custom/ImageCompresser";
import TimeInput from "../Reusable/TimeInput";
import dayjs from "dayjs";
import RadioOption from "../Reusable/RadioOption";
const SafetyForm = () => {
  const icon = (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5096 2.53165H7.41104C5.50437 2.52432 3.94146 4.04415 3.89654 5.9499V15.7701C3.85437 17.7071 5.38979 19.3121 7.32671 19.3552C7.35512 19.3552 7.38262 19.3561 7.41104 19.3552H14.7343C16.6538 19.2773 18.1663 17.6915 18.1525 15.7701V7.36798L13.5096 2.53165Z"
        stroke="#888888"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.2688 2.52084V5.18742C13.2688 6.48909 14.3211 7.54417 15.6228 7.54784H18.1482"
        stroke="#888888"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0974 14.0786H8.1474"
        stroke="#888888"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2229 10.6388H8.14655"
        stroke="#888888"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
  const token = Cookies.get("token");
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let currentMonth = new Date().toLocaleString("default", { month: "long" });
  const queryParams = new URLSearchParams(window.location.search);
  const idParam = Number(queryParams.get("id"));

  const navigate = useNavigate();

  const [sign, setSign] = useState();
  const [url, setUrl] = useState();

  const [foremanSign, setForemanSign] = useState();
  const [foremanUrl, setForemanUrl] = useState();

  const { deleteReportFile } = useDeleteFile();
  const { contactEmail, fetchEmail } = useFetchContactEmail();

  const { loggedInUser, setLoggedInUser } = useContext(DataContext);
  const currentDate = new Date();
  const currentTime = dayjs();
  const [formData, setFormData] = useState({
    ReportForWeekOf: currentMonth,
    StatusId: 1,
    Date: formatDate(currentDate),
    time: currentTime,
  });
  const [sLList, setSLList] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [selectedContact, setSelectedContact] = useState({});
  const [staffData, setStaffData] = useState([]);

  const [Files, setFiles] = useState([]);

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarColor, setSnackBarColor] = useState("");
  const [snackBarText, setSnackBarText] = useState("");
  const [prevFiles, setPrevFiles] = useState([]);

  const [loading, setLoading] = useState(true);

  const getWeeklyPreview = async () => {
    if (!idParam) {
      setLoading(false);
      return;
    }
    try {
      const res = await axios.get(
        `${baseUrl}/api/WeeklyReport/GetWeeklyReportRC?id=${idParam}`,
        { headers }
      );

      setFormData(res.data.Data);
      fetchEmail(res.data.Data.ContactId);
      setPrevFiles(res.data.FileData);
      setLoading(false);
      setSelectedContact({
        ...selectedContact,
        Email: res.data.Data.ContactEmail,
        CompanyName: res.data.Data.ContactCompany,
      });

      console.log("reponse weekly is", res.data);
    } catch (error) {
      setLoading(false);

      console.log("api call error", error);
    }
  };

  const fetchServiceLocations = async (id) => {
    axios
      .get(`${baseUrl}/api/Customer/GetCustomerServiceLocation?id=${id}`, {
        headers,
      })
      .then((res) => {
        setSLList(res.data);
        console.log("service locations are", res.data);
      })
      .catch((error) => {
        setSLList([]);
        console.log("service locations fetch error", error);
      });
  };
  const [storeLocations, setStoreLocations] = useState([]);

  const fetchStoreLocations = async () => {
    axios
      .get(`${baseUrl}/api/WeeklyReport/GetStoreLocationList`, { headers })
      .then((res) => {
        console.log("store locations are", res.data);
        setStoreLocations(res.data);
      })
      .catch((error) => {
        console.log("store locations fetch error", error);
      });
  };

  const fetctContacts = async (id) => {
    if (!id) {
      return;
    }
    axios
      .get(`${baseUrl}/api/Customer/GetCustomerContact?id=${id}`, { headers })
      .then((res) => {
        console.log("contacts data isss", res.data);
        setContactList(res.data);
      })
      .catch((error) => {
        setContactList([]);
        console.log("contacts data fetch error", error);
      });
  };
  const fetchStaffList = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/Staff/GetStaffList`, {
        headers,
      });
      setStaffData(response.data);

      console.log("staff list iss", response.data);
    } catch (error) {
      console.log("error getting staff list", error);
    }
  };

  const handleCustomerAutocompleteChange = (event, newValue) => {
    // Construct an event-like object with the structure expected by handleInputChange

    const simulatedEvent = {
      target: {
        name: "CustomerId",
        value: newValue ? newValue.UserId : "",
      },
    };
    handleInputChange(simulatedEvent);
  };
  const handleSLAutocompleteChange = (event, newValue) => {
    const simulatedEvent = {
      target: {
        name: "ServiceLocationId",
        value: newValue ? newValue.ServiceLocationId : "",
      },
    };

    handleInputChange(simulatedEvent);
  };

  const handleStoreAutocompleteChange = (event, newValue) => {
    const simulatedEvent = {
      target: {
        name: "StoreLocationId",
        value: newValue ? newValue.StoreLocationId : "",
      },
    };

    handleInputChange(simulatedEvent);
  };

  const handleContactAutocompleteChange = (event, newValue) => {
    console.log("contact", newValue);
    setSelectedContact(newValue);
    const simulatedEvent = {
      target: {
        name: "ContactId",
        value: newValue ? newValue.ContactId : "",
      },
    };

    handleInputChange(simulatedEvent);
  };

  const handleRBAutocompleteChange = (event, newValue) => {
    // Construct an event-like object with the structure expected by handleInputChange
    const simulatedEvent = {
      target: {
        name: "RegionalManagerId",
        value: newValue ? newValue.UserId : "",
      },
    };

    // Assuming handleInputChange is defined somewhere within YourComponent
    // Call handleInputChange with the simulated event
    handleInputChange(simulatedEvent);
  };

  const handleInputChange = (e, newValue) => {
    const { name, value, type, checked } = e.target;

    // Convert to number if the field is CustomerId, Qty, Rate, or EstimateStatusId
    const adjustedValue = [
      "UserId",
      "ServiceLocationId",
      "ContactId",
      "Nextweekrotation",
      "Thisweekrotation",
    ].includes(name)
      ? Number(value)
      : value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : adjustedValue,
    }));

    // if (name === "UserId" && value != 0) {
    //   console.log(value);
    //   fetchServiceLocations(value);
    //   fetctContacts(value);
    // }
    console.log("Weakly payload", formData);
  };

  const handleDeleteFile = (index) => {
    // Create a copy of the Files array without the file to be deleted
    const updatedFiles = [...Files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const trackFile = async (e) => {
    const uploadedFile = e.target.files[0];
    if (uploadedFile) {
      // const newFile = {
      // actualFile: uploadedFile,
      // name: uploadedFile.name,
      // caption: uploadedFile.name,
      // date: new Date().toLocaleDateString(),
      // };

      const compressedImg = await imageCompresser(uploadedFile);
      setFiles((prevFiles) => [...prevFiles, compressedImg]);
    }
  };
  const [submitClicked, setSubmitClicked] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  const handleSubmit = () => {
    setSubmitClicked(true);
    if (
      !formData.CustomerId
    ) {
      setOpenSnackBar(true);
      setSnackBarColor("error");
      setSnackBarText("Please fill all required fields");
      return;
    }
    if (
      Files.length>4 ||Files.length<2
    ) {
      setOpenSnackBar(true);
      setSnackBarColor("error");
      setSnackBarText("Please Upload Minimum of 2 and Maximum of 4 files");
      return;
    }

    const postData = new FormData();

    // Merge the current items with the new items for EstimateData
    const WeeklyReportData = {
      ...formData,
      CustomerName: formData.CustomerDisplayName,
      ContactName: selectedContact.FirstName,
      ContactCompany: selectedContact.CompanyName,
      ContactEmail: selectedContact.Email,
    };

    console.log("WeeklyReportData:", WeeklyReportData);
    // console.log("data:", data);

    postData.append("WeeklyReportRCData", JSON.stringify(WeeklyReportData));
    console.log(JSON.stringify(WeeklyReportData));
    // Appending files to postData
    Files.forEach((fileObj) => {
      postData.append("Files", fileObj);
    });

    [url].forEach((fileObj) => {
      postData.append("signatureImage", fileObj);
    });

    // submitData(postData);
  };

  // const appendFilesToFormData = (formData) => {
  //   Files.forEach((fileObj) => {
  //     formData.append("Files", fileObj.actualFile);
  //   });
  // };

  const submitData = async (postData) => {
    setDisableButton(true);
    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    };
    try {
      const response = await axios.post(
        `${baseUrl}/api/WeeklyReport/AddWeeklyReportRC`,
        postData,
        {
          headers,
        }
      );

      setTimeout(() => {
        navigate(`/weekly-reports/rising-canes-preview?id=${response.data.Id}`);
      }, 4000);
      setDisableButton(false);

      setOpenSnackBar(true);
      setSnackBarColor("success");
      setSnackBarText(response.data.Message);

      console.log("Data submitted successfully:", response.data.Id);
    } catch (error) {
      console.error("API Call Error:", error);
      setDisableButton(false);
      setOpenSnackBar(true);
      setSnackBarColor("error");
      setSnackBarText(error.response.data);
    }

    // Logging FormData contents (for debugging purposes)
    for (let [key, value] of postData.entries()) {
      console.log("fpayload....", key, value);
    }
    // window.location.reload();

    // console.log("post data izzz",postData);
  };

  useEffect(() => {
    fetchServiceLocations(formData.CustomerId);
    fetctContacts(formData.CustomerId);
    // fetchName(formData.CustomerId);
    console.log("main payload isss", formData);
  }, [formData.CustomerId]);

  useEffect(() => {
    fetchStaffList();
    getWeeklyPreview();
    fetchStoreLocations();
  }, []);

  return (
    <>
      <TitleBar icon={icon} title="Safety Inspection" />
      <EventPopups
        open={openSnackBar}
        setOpen={setOpenSnackBar}
        color={snackBarColor}
        text={snackBarText}
      />
      <div className="container-fluid">
        {loading ? (
          <div className="center-loader">
            <CircularProgress />
          </div>
        ) : (
          <div className="card">
            <div className="itemtitleBar ">
              <h4> Landscape Maintenance Job-Site Safety Inspection</h4>
            </div>
            <div className="card-body p-0 ">
              <div className="row mx-2 mt-3">
                <div className="col-md-3">
                  <label className="form-label">
                    Date <span className="text-danger">*</span>
                  </label>
                  <TextField
                    value={formatDate(formData.Date)}
                    name="Date"
                    onChange={handleInputChange}
                    className="input-limit-datepicker"
                    type="date"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </div>
                <div className="col-md-3">
                  <label className="form-label">
                    Safety Inspector <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control form-control-sm"
                    name="SafetyInspector"
                    value={formData.SafetyInspector}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-3">
                  <TimeInput
                    name={"time"}
                    title={"Time"}
                    formData={formData}
                    setFormData={setFormData}
                  />
                </div>
                <div className="col-md-3" style={{ position: "relative" }}>
                  <label className="form-label">
                    Customers <span className="text-danger">*</span>
                  </label>

                  <CustomerAutocomplete
                    formData={formData}
                    setFormData={setFormData}
                    submitClicked={submitClicked}
                  />
                </div>
                <div className="col-md-3  mt-2">
                  <label className="form-label">
                    City <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control form-control-sm"
                    name="City"
                    value={formData.City}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-3  mt-2">
                  <label className="form-label">
                    Current weather at time of inspection{" "}
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control form-control-sm"
                    name="SafetyInspector"
                    value={formData.SafetyInspector}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-3  mt-2">
                  <label className="form-label">
                    Foreman<span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control form-control-sm"
                    name="foreman"
                    value={formData.foreman}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-lg-3 col-md-3  mt-2">
                  <label className="form-label">Number of Crew members:</label>
                  <FormControl fullWidth>
                    <Select
                      name="numberOfCrew"
                      value={formData.numberOfCrew || 1}
                      onChange={handleInputChange}
                      size="small"
                    >
                      {[1, 2, 3, 4, 5].map((num) => (
                        <MenuItem key={num} value={num}>
                          {num}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div className="col-md-3  mt-2">
                  <label className="form-label">
                    Truck #<span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control form-control-sm"
                    type="number"
                    name="truckNo"
                    value={formData.truckNo}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>

            <div className="card-body p-0">
              <div className="row mt-5 mx-2 ">
                <div className="col-md-6 col-sm-12">
                  <div className="itemtitleBar mb-3">
                    <h4>Crew Inspection</h4>
                  </div>
                  {[
                    {
                      title: "Did foreman have a morning 'huddle-up'",
                      name: "MorningHuddle",
                    },
                    {
                      title: "Does the crew have water in their water jugs?",
                      name: "CrewWater",
                    },
                    {
                      title:
                        "Does the foreman have 1.5 gallons of water per man?",
                      name: "WaterPerMan",
                    },
                    {
                      title:
                        "Did the foreman identify, communicate, and correct hazards?",
                      name: "HazardIdentification",
                    },
                    {
                      title: "Does the foreman have the IIPP copy in truck? ",
                      name: "IIPPInTruck",
                    },
                    {
                      title:
                        "Does the foreman have a map to the nearest medical clinic?",
                      name: "MedicalClinicMap",
                    },
                    {
                      title: "Does the foreman have a first aid kit?",
                      name: "FirstAidKit",
                    },
                    {
                      title: "Does the crew have cones?",
                      name: "CrewCones",
                    },
                    {
                      title: "Does the foreman know the weather?",
                      name: "WeatherAwareness",
                    },
                    {
                      title:
                        "Did foreman conduct the latest Safety Tailgate Meeting?",
                      name: "SafetyTailgateMeeting",
                    },
                    {
                      title:
                        "Did foreman give his crew correct rest and meal periods?",
                      name: "RestAndMealPeriods",
                    },
                    {
                      title: "Power tools are in good condition?",
                      name: "PowerToolCondition",
                    },
                    {
                      title: "Crew is wearing safety vest?",
                      name: "SafetyVest",
                    },
                    {
                      title: "Safety Glass are on when needed?",
                      name: "SafetyGlass",
                    },
                    {
                      title: "Gloves when needed?",
                      name: "Gloves",
                    },
                    {
                      title: "Earplugs when needed?",
                      name: "Earplugs",
                    },
                    {
                      title: "Truck is clean inside? ",
                      name: "TruckCleanInside",
                    },
                    {
                      title: "Truck is clean outside?",
                      name: "TruckCleanOutside",
                    },
                  ].map((inp, index) => (
                    <div key={index} className="col-md-12">
                      <RadioOption
                        name={inp.name}
                        title={inp.title}
                        formData={formData}
                        setFormData={setFormData}
                        options={[
                          { value: "No", label: "No" },
                          { value: "Yes", label: "Yes" },
                        ]}
                      />
                    </div>
                  ))}
                  <div className="col-md-12 mt-2">
                    <label className="form-label">Job Comments / Issues</label>

                    <input
                      className="form-control form-control-sm"
                      name="JobCommentsIssues"
                      value={formData.JobCommentsIssues}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-12 mt-2">
                    <label className="form-label">Action Items</label>

                    <input
                      className="form-control form-control-sm"
                      name="ActionItems"
                      value={formData.ActionItems}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>

                <div className="col-md-6 col-sm-12">
                  <div className="itemtitleBar mb-3">
                    <h4>Truck Inspection</h4>
                  </div>
                  {[
                    {
                      title: "Emergency Flashers",
                      name: "EmergencyFlashers",
                    },
                    {
                      title: "Windshield Wipers",
                      name: "WindshieldWipers",
                    },
                    {
                      title: "Horn",
                      name: "Horn",
                    },
                    {
                      title: "Mirrors",
                      name: "Mirrors",
                    },
                    {
                      title: "Seat Belts",
                      name: "SeatBelts",
                    },
                    {
                      title: "License Plate and Registration",
                      name: "LicensePlateAndRegistration",
                    },
                    {
                      title: "Fire Extinguisher",
                      name: "FireExtinguisher",
                    },
                    {
                      title: "Fluids",
                      name: "Fluids",
                    },
                    {
                      title: "Turn Signals",
                      name: "TurnSignals",
                    },
                    {
                      title: "Brakes",
                      name: "Brakes",
                    },
                    {
                      title: "Tires",
                      name: "Tires",
                    },
                    {
                      title: "Samsara Camera",
                      name: "SamsaraCamera",
                    },
                  ].map((inp, index) => (
                    <div key={index} className="col-md-12">
                      <div className="row">
                        <div className="w-auto pe-0 text-end mt-1">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            name={inp.name}
                            value={formData[inp.name]}
                            checked={formData[inp.name]}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="col-md-11  mt-2">
                          <h5>{inp.title}</h5>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="col-md-12 mt-2">
                    <label className="form-label">
                      Logo and overall truck appearance
                    </label>

                    <input
                      className="form-control form-control-sm"
                      name="truckappearance"
                      value={formData.truckappearance}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-12 mt-2">
                    <label className="form-label">
                      List any problems that need correction
                    </label>

                    <input
                      className="form-control form-control-sm"
                      name="correction"
                      value={formData.correction}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="itemtitleBar mb-3">
                    <h4>Trailer Inspection (skip if no trailer)</h4>
                  </div>
                  {[
                    {
                      title: "Tow hitch ball has a lock",
                      name: "TowHitchBallLock",
                    },
                    {
                      title: "Trailer has safety chains connected to truck",
                      name: "TrailerSafetyChains",
                    },
                    {
                      title: "Trailer is connected correctly",
                      name: "TrailerConnection",
                    },
                    {
                      title: "Turn Signals functioning correctly",
                      name: "TurnSignalsFunctioning",
                    },
                    {
                      title: "Electrical connection correct",
                      name: "ElectricalConnection",
                    },
                    {
                      title:
                        "Trailer has proper registration and license plate",
                      name: "TrailerRegistrationAndLicense",
                    },
                    {
                      title: "Tires",
                      name: "TrailerTires",
                    },
                  ].map((inp, index) => (
                    <div key={index} className="col-md-12">
                      <div className="row">
                        <div className="w-auto pe-0 text-end mt-1">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            name={inp.name}
                            value={formData[inp.name]}
                            checked={formData[inp.name]}
                            onChange={handleInputChange}
                          />
                        </div>{" "}
                        <div className="col-md-11  mt-2">
                          <h5>{inp.title}</h5>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="col-md-12 mt-2">
                    <label className="form-label">
                      List any problems that need correction
                    </label>

                    <input
                      className="form-control form-control-sm"
                      name="correction"
                      value={formData.correction}
                      onChange={handleInputChange}
                    />
                  </div>
                  
                  <div className="col-md-12 mt-2">
                    <label className="form-label">
                      Foreman Signature
                    </label>
                    <div className="d-flex justify-content-between mt-2">
                      <SignInput
                        sign={foremanSign}
                        setSign={setForemanSign}
                        url={foremanUrl}
                        setUrl={setForemanUrl}
                      />

                      {foremanUrl ? (
                        <img
                          src={URL.createObjectURL(foremanUrl)}
                          style={{ height: 100, width: 200 }}
                        />
                      ) : (
                        <>
                          {formData.FilePath ? (
                            <img
                              src={`${baseUrl}/${formData.FilePath}`}
                              style={{
                                height: 100,
                                width: 200,
                              }}
                            />
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 mt-2">
                    <label className="form-label">
                      Safety Inspector Signature
                    </label>
                    <div className="d-flex justify-content-between mt-2">
                      <SignInput
                        sign={sign}
                        setSign={setSign}
                        url={url}
                        setUrl={setUrl}
                        
                         modalId="inspectorModal"
                      />

                      {url ? (
                        <img
                          src={URL.createObjectURL(url)}
                          style={{ height: 100, width: 200 }}
                        />
                      ) : (
                        <>
                          {formData.FilePath ? (
                            <img
                              src={`${baseUrl}/${formData.FilePath}`}
                              style={{
                                height: 100,
                                width: 200,
                              }}
                            />
                          ) : (
                            <></>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt-2 mx-2">
                <div className="col-md-2 col-lg-2">
                  <FileUploadButton onClick={trackFile}>
                    Site Photos
                  </FileUploadButton>

                  {/* <div className="basic-form">
                        <label className="form-label">Site Photos</label>
                        <div className="dz-default dlab-message upload-img mb-3">
                          <form action="#" className="dropzone">
                            <svg
                              width="41"
                              height="40"
                              viewBox="0 0 41 40"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M27.1666 26.6667L20.4999 20L13.8333 26.6667"
                                stroke="#DADADA"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                              <path
                                d="M20.5 20V35"
                                stroke="#DADADA"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                              <path
                                d="M34.4833 30.6501C36.1088 29.7638 37.393 28.3615 38.1331 26.6644C38.8731 24.9673 39.027 23.0721 38.5703 21.2779C38.1136 19.4836 37.0724 17.8926 35.6111 16.7558C34.1497 15.619 32.3514 15.0013 30.4999 15.0001H28.3999C27.8955 13.0488 26.9552 11.2373 25.6498 9.70171C24.3445 8.16614 22.708 6.94647 20.8634 6.1344C19.0189 5.32233 17.0142 4.93899 15.0001 5.01319C12.9861 5.0874 11.015 5.61722 9.23523 6.56283C7.45541 7.50844 5.91312 8.84523 4.7243 10.4727C3.53549 12.1002 2.73108 13.9759 2.37157 15.959C2.01205 17.9421 2.10678 19.9809 2.64862 21.9222C3.19047 23.8634 4.16534 25.6565 5.49994 27.1667"
                                stroke="#DADADA"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                              <path
                                d="M27.1666 26.6667L20.4999 20L13.8333 26.6667"
                                stroke="#DADADA"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              ></path>
                            </svg>
                            <div className="fallback mb-3">
                              <input name="file" type="file" onChange={trackFile} />
                            </div>
                          </form>
                        </div>
                      </div> */}
                </div>

                {Files.map((file, index) => (
                  <div
                    key={index}
                    className="col-md-2 col-md-2 mt-3 image-container"
                    style={{
                      width: "115px", // Set the desired width
                      height: "110px", // Set the desired height
                      margin: "1em",
                      position: "relative",
                    }}
                  >
                    <img
                      src={URL.createObjectURL(file)}
                      alt={file.name}
                      style={{
                        width: "115px",
                        height: "110px",
                        objectFit: "cover",
                      }}
                    />
                    <p
                      className="file-name-overlay"
                      style={{
                        position: "absolute",
                        bottom: "0",
                        left: "0px",
                        right: "0",
                        backgroundColor: "rgba(0, 0, 0, 0.3)",
                        textAlign: "center",
                        overflow: "hidden",

                        textOverflow: "ellipsis",
                        padding: "5px",
                      }}
                    >
                      {file.name}
                    </p>
                    <span
                      className="file-delete-button"
                      style={{
                        left: "90px",
                      }}
                      onClick={() => {
                        handleDeleteFile(index);
                      }}
                    >
                      <span>
                        <Delete color="error" />
                      </span>
                    </span>
                  </div>
                ))}
                {prevFiles.map((file, index) => {
                  return (
                    <div
                      key={index}
                      className="col-md-2 col-md-2 mt-3 image-container"
                      style={{
                        width: "115px", // Set the desired width
                        height: "110px", // Set the desired height
                        margin: "1em",
                        position: "relative",
                      }}
                    >
                      <a
                        href={`${baseUrl}/${file.FilePath}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={`${baseUrl}/${file.FilePath}`}
                          style={{
                            width: "115px",
                            height: "110px",
                            objectFit: "cover",
                          }}
                        />
                      </a>
                      <p
                        className="file-name-overlay"
                        style={{
                          position: "absolute",
                          bottom: "0",
                          left: "0px",
                          right: "0",
                          backgroundColor: "rgba(0, 0, 0, 0.3)",
                          textAlign: "center",
                          overflow: "hidden",

                          textOverflow: "ellipsis",
                          padding: "5px",
                        }}
                      ></p>
                      <span
                        className="file-delete-button"
                        style={{
                          left: "90px",
                        }}
                        onClick={() => {
                          deleteReportFile(
                            "WeeklyReport/DeleteWeeklyReportRCFile?FileId=",
                            file.WeeklyReportRCFileId,
                            getWeeklyPreview
                          );
                        }}
                      >
                        <span>
                          <Delete color="error" />
                        </span>
                      </span>
                    </div>
                  );
                })}
              </div>
              <div className="row m-2 mb-3">
                <div className="col-md-4 ps-0 mt-2 ">
                  {" "}
                  <div className="ms-2">
                    <BackButton
                      onClick={() => {
                        navigate(`/weekly-reports/rising-canes`);
                      }}
                    >
                      Back
                    </BackButton>
                  </div>
                </div>
                <div className="col-md-8 ps-0 mt-2  text-end">
                  {idParam ? (
                    <>
                      <PrintButton
                        varient="mail"
                        onClick={() => {
                          navigate(
                            `/send-mail?title=${"Weekly Report - Rising Canes"}&mail=${contactEmail}`
                          );
                        }}
                      >
                        <Email />
                      </PrintButton>

                      <PrintButton
                        varient="print"
                        onClick={() => {
                          navigate(
                            `/weekly-reports/rising-canes-preview?id=${idParam}`
                          );
                        }}
                      ></PrintButton>
                    </>
                  ) : (
                    <></>
                  )}

                  <LoaderButton
                    loading={disableButton}
                    handleSubmit={handleSubmit}
                  >
                    Save and Preview
                  </LoaderButton>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default SafetyForm;
