import React, { useEffect, useState, useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  TextField,
  TablePagination,
  Checkbox,
  Button,
  Grid,
} from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Cookies from "js-cookie";
import { Delete, Create } from "@mui/icons-material";
import axios from "axios";
import TblDateFormat from "../../custom/TblDateFormat";
import { DataContext } from "../../context/AppData";
import AddButton from "../Reusable/AddButton";
import { baseUrl } from "../../apiConfig";
import ArrowOutwardIcon from "@mui/icons-material/OpenInNew";
import DeleteModal from "../CommonComponents/DeleteModal";
import Authorization from "../Reusable/Authorization"
import TitleBar from "../TitleBar";

const SafetyList = () => {
    const icon = (
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.5096 2.53165H7.41104C5.50437 2.52432 3.94146 4.04415 3.89654 5.9499V15.7701C3.85437 17.7071 5.38979 19.3121 7.32671 19.3552C7.35512 19.3552 7.38262 19.3561 7.41104 19.3552H14.7343C16.6538 19.2773 18.1663 17.6915 18.1525 15.7701V7.36798L13.5096 2.53165Z"
            stroke="#888888"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.2688 2.52084V5.18742C13.2688 6.48909 14.3211 7.54417 15.6228 7.54784H18.1482"
            stroke="#888888"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.0974 14.0786H8.1474"
            stroke="#888888"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M11.2229 10.6388H8.14655"
            stroke="#888888"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    const headers = {
        Authorization: `Bearer ${Cookies.get("token")}`,
      };
      const queryParams = new URLSearchParams(window.location.search);
      const customerParam = Number(queryParams.get("CustomerId"));
      const [page, setPage] = useState(0);
      const [rowsPerPage, setRowsPerPage] = useState(100);
    
    
      const [reports, setReports] = useState([]);
    
      const [isLoading, setIsLoading] = useState(false);
      const { loggedInUser } = useContext(DataContext);
    
      const navigate = useNavigate();
    
      const fetchReports = async () => {
        try {
          const response = await axios.get(
            `${baseUrl}/api/MonthlyLandsacpe/GetMonthlyLandsacpeList`,
            { headers }
          );
          response.data.sort((a, b) => b.MonthlyLandsacpeId - a.MonthlyLandsacpeId);
          setReports(response.data);
    
        //   setRecords({
        //     open: response.data.filter((report) => report.StatusId === 1).length,
        //     closed: response.data.filter((report) => report.StatusId === 2).length,
        //   });
    
          console.log("////////", response.data);
          if (response.data != null) {
            setIsLoading(false);
          }
        } catch (error) {
          console.log("api call error", error);
          setIsLoading(false);
        }
      };
    
      const [search, setSearch] = useState("");
    
      let filteredReports = reports
        // .filter((report) => statusId === 0 || report.StatusId === statusId)
        // .filter(
        //   (report) =>
        //     report.RequestByName?.toLowerCase().includes(search.toLowerCase()) ||
        //     report.DisplayName?.toLowerCase().includes(search.toLowerCase())
        // );
    
      useEffect(() => {
        // fetchReports();
      }, []);
    
      return (
        <>
        <TitleBar icon={icon} title={"Safety Inspection"}></TitleBar>
        <div className="container-fluid">
          {isLoading ? (
            <div className="center-loader">
              <CircularProgress style={{ color: "#789a3d" }} />
            </div>
          ) : (
            <div className="card">
                <div className="card-body">
              <div className="row ">
                <div className="col-md-3">
                  <TextField
                    label="Search"
                    variant="standard"
                    size="small"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
                <div className="col-md-9">
                  <div className="custom-button-container mb-2">
                    {/* {loggedInUser.userRole == "1" && ( */}
                    <AddButton
                      onClick={() => {
                        navigate("/safety-reports/add");
                      }}
                    >
                      Add Saftey Report
                    </AddButton>
                    {/* )} */}
                  </div>
                </div>
              </div>{" "}
              <TableContainer sx={{ overflowX: "auto" }}>
                <Table>
                  <TableHead className="table-header ">
                    <TableRow className="material-tbl-alignment">
                      {/* <TableCell padding="checkbox">
                      <Checkbox />
                    </TableCell> */}
                      {[
                        "#",
                        "Date",
                        "Safety Inspector",
                        "Time",
                        "Customer Name",
                        "City",
                        "Foreman",
                        "Number of Crew Mempers",
                        "truck #",
                      ].map((headCell) => (
                        <TableCell key={headCell}>{headCell}</TableCell>
                      ))}
                      {loggedInUser.userRole == 1 && (
                        <TableCell align="right">Actions</TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredReports.length <= 0 ? (
                      <TableRow>
                        {" "}
                        <TableCell align="center" colSpan={12}>
                          No Record Found
                        </TableCell>
                      </TableRow>
                    ) : (
                      filteredReports.map((report, index) => (
                        <TableRow
                          key={report.MonthlyLandsacpeId}
                          hover
                          className="material-tbl-alignment"
                        >
                          <TableCell
                            onClick={() => {
                              navigate(
                                `/safety-reports/add?id=${report.MonthlyLandsacpeId}`
                              );
                            }}
                          >
                            {report.MonthlyLandsacpeId}
                          </TableCell>
                          <TableCell>
                            {report.DisplayName}{" "}
                            <NavLink
                              to={`/customers/add-customer?id=${report.CustomerId}`}
                              target="_blank"
                            >
                              <ArrowOutwardIcon style={{ fontSize: 14 }} />
                            </NavLink>
                          </TableCell>
    
                          <TableCell
                            onClick={() => {
                              if (loggedInUser.userRole=="2") {
                                navigate(`safety-reports/preview?id=${report.MonthlyLandsacpeId}&CustomerId=${report.CustomerId}`)
                                return
                              }
                              navigate(
                                `/safety-reports/add?id=${report.MonthlyLandsacpeId}`
                              );
                            }}
                          >
                            {report.RequestByName}
                          </TableCell>
    
                          <TableCell
                            onClick={() => {
                              if (loggedInUser.userRole=="2") {
                                navigate(`safety-reports/preview?id=${report.MonthlyLandsacpeId}&CustomerId=${report.CustomerId}`)
                                return
                              }
                              navigate(
                                `/safety-reports/add?id=${report.MonthlyLandsacpeId}`
                              );
                            }}
                          >
                            <span
                              style={{
                                backgroundColor: report.ReportStatusColor,
                              }}
                              className="span-hover-pointer badge badge-pill  "
                            >
                              {report.ReportStatus}
                            </span>
                          </TableCell>
    
                          <TableCell
                            onClick={() => {
                              if (loggedInUser.userRole=="2") {
                                navigate(`safety-reports/preview?id=${report.MonthlyLandsacpeId}&CustomerId=${report.CustomerId}`)
                                return
                              }
                              navigate(
                                `/safety-reports/add?id=${report.MonthlyLandsacpeId}`
                              );
                            }}
                          >
                            {TblDateFormat(report.MonthDate)}
                          </TableCell>
    
                          {loggedInUser.userRole == 1 && (
                            <TableCell align="right">
                              <DeleteModal
                                endPoint={`MonthlyLandsacpe/DeleteMonthlyLandsacpe`}
                                successFun={fetchReports}
                                deleteId={report.MonthlyLandsacpeId}
                              />
                            </TableCell>
                          )}
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[100, 200, 300]}
                component="div"
                count={filteredReports.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(event, newPage) => setPage(newPage)}
                onRowsPerPageChange={(event) => {
                  setRowsPerPage(parseInt(event.target.value, 10));
                  setPage(0);
                }}
              />
            </div></div>
          )}
        </div></>
      );
    };
    
export default SafetyList