import React, { useContext, useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { StyleContext } from "../../context/StyleData";
import { DataContext } from "../../context/AppData";
import Cookies from "js-cookie";
import TaskIcon from "@mui/icons-material/EngineeringOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import SpeedIcon from "@mui/icons-material/Speed";
import { GrDocumentPerformance } from "react-icons/gr";
import Authorization from "../Reusable/Authorization";

const SideBar = () => {
  const subShowRef = useRef(null);
  const irrShowRef = useRef(null);
  const pLShowRef = useRef(null);
  const sidebarRef = useRef(null);

  const {
    showSubMenu,
    setShowSM,
    mainControl,
    setMainControl,
    eliminate,
    showIrrMenu,
    setShowIrrMenu,
    showPlMenu,
    setShowPlMenu,
  } = useContext(StyleContext);
  const { loggedInUser, setLoggedInUser } = useContext(DataContext);

  const [activeIndex, setActiveIndex] = useState(0);
  const [subClass, setSubClass] = useState(-1);

  const [showSubMenuHov, setSubHov] = useState(false);

  const sideBarData = [
    {
      label: "Dashboard",
      path: "/dashboard",
      icon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.5 7.49999L10 1.66666L17.5 7.49999V16.6667C17.5 17.1087 17.3244 17.5326 17.0118 17.8452C16.6993 18.1577 16.2754 18.3333 15.8333 18.3333H4.16667C3.72464 18.3333 3.30072 18.1577 2.98816 17.8452C2.67559 17.5326 2.5 17.1087 2.5 16.6667V7.49999Z"
            stroke="#888888"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.5 18.3333V10H12.5V18.3333"
            stroke="#888888"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    // {
    //   label: "KPIs",
    //   path: "/kpi-dashboard",
    //   icon: <GrDocumentPerformance size={"20px"} color="#8c8b8b"/>,
    // },
    {
      label: "Customers",
      path: "/customers",
      icon: (
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.986 14.0673C7.4407 14.0673 4.41309 14.6034 4.41309 16.7501C4.41309 18.8969 7.4215 19.4521 10.986 19.4521C14.5313 19.4521 17.5581 18.9152 17.5581 16.7693C17.5581 14.6234 14.5505 14.0673 10.986 14.0673Z"
            stroke="#888888"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.986 11.0054C13.3126 11.0054 15.1983 9.11881 15.1983 6.79223C15.1983 4.46564 13.3126 2.57993 10.986 2.57993C8.65944 2.57993 6.77285 4.46564 6.77285 6.79223C6.76499 9.11096 8.63849 10.9975 10.9563 11.0054H10.986Z"
            stroke="#888888"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      label: "Staff Management",
      path: "/staff",
      icon: (
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.5 17H4a1 1 0 0 1-1-1 3 3 0 0 1 3-3h1m0-3a2.5 2.5 0 1 1 2-4.5M19.5 17h.5c.6 0 1-.4 1-1a3 3 0 0 0-3-3h-1m0-3a2.5 2.5 0 1 0-2-4.5m.5 13.5h-7a1 1 0 0 1-1-1 3 3 0 0 1 3-3h3a3 3 0 0 1 3 3c0 .6-.4 1-1 1Zm-1-9.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z"
            stroke="#888888"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.5 17H4a1 1 0 0 1-1-1 3 3 0 0 1 3-3h1m0-3a2.5 2.5 0 1 1 2-4.5M19.5 17h.5c.6 0 1-.4 1-1a3 3 0 0 0-3-3h-1m0-3a2.5 2.5 0 1 0-2-4.5m.5 13.5h-7a1 1 0 0 1-1-1 3 3 0 0 1 3-3h3a3 3 0 0 1 3 3c0 .6-.4 1-1 1Zm-1-9.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z"
            stroke="#888888"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      label: "Map",
      path: "/map",
      icon: <LocationOnOutlinedIcon sx={{ color: "#888888", fontSize: 20 }} />,
    },

    {
      label: "Service Requests",
      path: "/service-requests",
      icon: (
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 7V6c0-.6.4-1 1-1h11c.6 0 1 .4 1 1v7c0 .6-.4 1-1 1h-1M3 18v-7c0-.6.4-1 1-1h11c.6 0 1 .4 1 1v7c0 .6-.4 1-1 1H4a1 1 0 0 1-1-1Zm8-3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
            stroke="#888888"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },

    {
      label: "Estimates",
      path: "/estimates",
      icon: (
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.8381 12.7317C16.4566 12.7317 16.9757 13.2422 16.8811 13.853C16.3263 17.4463 13.2502 20.1143 9.54009 20.1143C5.43536 20.1143 2.10834 16.7873 2.10834 12.6835C2.10834 9.30245 4.67693 6.15297 7.56878 5.44087C8.19018 5.28745 8.82702 5.72455 8.82702 6.36429C8.82702 10.6987 8.97272 11.8199 9.79579 12.4297C10.6189 13.0396 11.5867 12.7317 15.8381 12.7317Z"
            stroke="#888888"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.8848 9.1223C19.934 6.33756 16.5134 1.84879 12.345 1.92599C12.0208 1.93178 11.7612 2.20195 11.7468 2.5252C11.6416 4.81493 11.7834 7.78204 11.8626 9.12713C11.8867 9.5459 12.2157 9.87493 12.6335 9.89906C14.0162 9.97818 17.0914 10.0862 19.3483 9.74467C19.6552 9.69835 19.88 9.43204 19.8848 9.1223Z"
            stroke="#888888"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },

    {
      label: "Purchase Order",
      path: "/purchase-order",
      icon: (
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.4065 14.8714H7.78821"
            stroke="#888888"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
          <path
            d="M14.4065 11.0338H7.78821"
            stroke="#888888"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
          <path
            d="M10.3137 7.2051H7.78827"
            stroke="#888888"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.5829 2.52066C14.5829 2.52066 7.54563 2.52433 7.53463 2.52433C5.00463 2.53991 3.43805 4.20458 3.43805 6.74374V15.1734C3.43805 17.7254 5.01655 19.3965 7.56855 19.3965C7.56855 19.3965 14.6049 19.3937 14.6168 19.3937C17.1468 19.3782 18.7143 17.7126 18.7143 15.1734V6.74374C18.7143 4.19174 17.1349 2.52066 14.5829 2.52066Z"
            stroke="#888888"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </svg>
      ),
    },
    {
      label: "Bill",
      path: "/bills",
      icon: (
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 3v4c0 .6-.4 1-1 1H5m8-2h3m-3 3h3m-4 3v6m4-3H8M19 4v16c0 .6-.4 1-1 1H6a1 1 0 0 1-1-1V8c0-.4.1-.6.3-.8l4-4 .6-.2H18c.6 0 1 .4 1 1ZM8 12v6h8v-6H8Z"
            stroke="#888888"
          />

          <path />
        </svg>
      ),
    },
    {
      label: "Invoice",
      path: "/invoices",
      icon: (
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 4h3c.6 0 1 .4 1 1v15c0 .6-.4 1-1 1H6a1 1 0 0 1-1-1V5c0-.6.4-1 1-1h3m0 3h6m-3 5h3m-6 0h0m3 4h3m-6 0h0m1-13v4h4V3h-4Z"
            stroke="#888888"
          />
        </svg>
      ),
    },

    {
      label: "Items",
      path: "/items",
      icon: (
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.1 4H5c-.5 0-.9.4-.9.9V9c0 .5.4.9.9.9h4c.5 0 .9-.4.9-.9V5c0-.5-.4-.9-.9-.9Zm10 0H15c-.5 0-.9.4-.9.9V9c0 .5.4.9.9.9h4c.5 0 .9-.4.9-.9V5c0-.5-.4-.9-.9-.9Zm-10 10H5c-.5 0-.9.4-.9.9V19c0 .5.4.9.9.9h4c.5 0 .9-.4.9-.9v-4c0-.5-.4-.9-.9-.9Zm10 0H15c-.5 0-.9.4-.9.9V19c0 .5.4.9.9.9h4c.5 0 .9-.4.9-.9v-4c0-.5-.4-.9-.9-.9Z"
            stroke="#888888"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      label: "Wages",
      path: "/wages",
      icon: <TaskIcon sx={{ color: "#888888", fontSize: 20 }} />,
    },
    {
      label: "Monthly Goals",
      path: "/monthly-goals",
      icon: <SpeedIcon sx={{ color: "#888888", fontSize: 20 }} />,
    },
  ];

  const [sideBarDataRM, setSideBarDataRM] = useState([
    {
      label: "Dashboard",
      path: "/dashboard",
      icon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.5 7.49999L10 1.66666L17.5 7.49999V16.6667C17.5 17.1087 17.3244 17.5326 17.0118 17.8452C16.6993 18.1577 16.2754 18.3333 15.8333 18.3333H4.16667C3.72464 18.3333 3.30072 18.1577 2.98816 17.8452C2.67559 17.5326 2.5 17.1087 2.5 16.6667V7.49999Z"
            stroke="#888888"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.5 18.3333V10H12.5V18.3333"
            stroke="#888888"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    // {
    //   label: "KPIs",
    //   path: "/kpi-dashboard",
    //   icon: <GrDocumentPerformance size={"20px"} color="#8c8b8b"/>,
    // },

    {
      label: "Customers",
      path: "/customers",
      icon: (
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.986 14.0673C7.4407 14.0673 4.41309 14.6034 4.41309 16.7501C4.41309 18.8969 7.4215 19.4521 10.986 19.4521C14.5313 19.4521 17.5581 18.9152 17.5581 16.7693C17.5581 14.6234 14.5505 14.0673 10.986 14.0673Z"
            stroke="#888888"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.986 11.0054C13.3126 11.0054 15.1983 9.11881 15.1983 6.79223C15.1983 4.46564 13.3126 2.57993 10.986 2.57993C8.65944 2.57993 6.77285 4.46564 6.77285 6.79223C6.76499 9.11096 8.63849 10.9975 10.9563 11.0054H10.986Z"
            stroke="#888888"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },

    {
      label: "Map",
      path: "/map",
      icon: <LocationOnOutlinedIcon sx={{ color: "#888888", fontSize: 20 }} />,
    },
    {
      label: "Service Requests",
      path: "/service-requests",
      icon: (
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 7V6c0-.6.4-1 1-1h11c.6 0 1 .4 1 1v7c0 .6-.4 1-1 1h-1M3 18v-7c0-.6.4-1 1-1h11c.6 0 1 .4 1 1v7c0 .6-.4 1-1 1H4a1 1 0 0 1-1-1Zm8-3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
            stroke="#888888"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      label: "Estimates",
      path: "/estimates",
      icon: (
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.8381 12.7317C16.4566 12.7317 16.9757 13.2422 16.8811 13.853C16.3263 17.4463 13.2502 20.1143 9.54009 20.1143C5.43536 20.1143 2.10834 16.7873 2.10834 12.6835C2.10834 9.30245 4.67693 6.15297 7.56878 5.44087C8.19018 5.28745 8.82702 5.72455 8.82702 6.36429C8.82702 10.6987 8.97272 11.8199 9.79579 12.4297C10.6189 13.0396 11.5867 12.7317 15.8381 12.7317Z"
            stroke="#888888"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.8848 9.1223C19.934 6.33756 16.5134 1.84879 12.345 1.92599C12.0208 1.93178 11.7612 2.20195 11.7468 2.5252C11.6416 4.81493 11.7834 7.78204 11.8626 9.12713C11.8867 9.5459 12.2157 9.87493 12.6335 9.89906C14.0162 9.97818 17.0914 10.0862 19.3483 9.74467C19.6552 9.69835 19.88 9.43204 19.8848 9.1223Z"
            stroke="#888888"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      label: "Purchase Order",
      path: "/purchase-order",
      icon: (
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.4065 14.8714H7.78821"
            stroke="#888888"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
          <path
            d="M14.4065 11.0338H7.78821"
            stroke="#888888"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
          <path
            d="M10.3137 7.2051H7.78827"
            stroke="#888888"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.5829 2.52066C14.5829 2.52066 7.54563 2.52433 7.53463 2.52433C5.00463 2.53991 3.43805 4.20458 3.43805 6.74374V15.1734C3.43805 17.7254 5.01655 19.3965 7.56855 19.3965C7.56855 19.3965 14.6049 19.3937 14.6168 19.3937C17.1468 19.3782 18.7143 17.7126 18.7143 15.1734V6.74374C18.7143 4.19174 17.1349 2.52066 14.5829 2.52066Z"
            stroke="#888888"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></path>
        </svg>
      ),
    },

    {
      label: "Items",
      path: "/items",
      icon: (
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.1 4H5c-.5 0-.9.4-.9.9V9c0 .5.4.9.9.9h4c.5 0 .9-.4.9-.9V5c0-.5-.4-.9-.9-.9Zm10 0H15c-.5 0-.9.4-.9.9V9c0 .5.4.9.9.9h4c.5 0 .9-.4.9-.9V5c0-.5-.4-.9-.9-.9Zm-10 10H5c-.5 0-.9.4-.9.9V19c0 .5.4.9.9.9h4c.5 0 .9-.4.9-.9v-4c0-.5-.4-.9-.9-.9Zm10 0H15c-.5 0-.9.4-.9.9V19c0 .5.4.9.9.9h4c.5 0 .9-.4.9-.9v-4c0-.5-.4-.9-.9-.9Z"
            stroke="#888888"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      label: "Wages",
      path: "/wages",
      icon: <TaskIcon sx={{ color: "#888888", fontSize: 20 }} />,
    },
  ]);

  const sideBarDataIrr = [
    {
      label: "Dashboard",
      path: "/dashboard",
      icon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.5 7.49999L10 1.66666L17.5 7.49999V16.6667C17.5 17.1087 17.3244 17.5326 17.0118 17.8452C16.6993 18.1577 16.2754 18.3333 15.8333 18.3333H4.16667C3.72464 18.3333 3.30072 18.1577 2.98816 17.8452C2.67559 17.5326 2.5 17.1087 2.5 16.6667V7.49999Z"
            stroke="#888888"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7.5 18.3333V10H12.5V18.3333"
            stroke="#888888"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      label: "Map",
      path: "/map",
      icon: <LocationOnOutlinedIcon sx={{ color: "#888888", fontSize: 20 }} />,
    },
    {
      label: "Service Requests",
      path: "/service-requests",
      icon: (
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 7V6c0-.6.4-1 1-1h11c.6 0 1 .4 1 1v7c0 .6-.4 1-1 1h-1M3 18v-7c0-.6.4-1 1-1h11c.6 0 1 .4 1 1v7c0 .6-.4 1-1 1H4a1 1 0 0 1-1-1Zm8-3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
            stroke="#888888"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    // {
    //   label: "SprayTech",
    //   path: "/spray-tech",
    //   icon: (
    //     <svg
    //       width="22"
    //       height="22"
    //       viewBox="0 0 22 22"
    //       fill="none"
    //       xmlns="http://www.w3.org/2000/svg"
    //     >
    //       <path
    //         d="M4.4 7.7c2 .5 2.4 2.8 3.2 3.8 1 1.4 2 1.3 3.2 2.7 1.8 2.3 1.3 5.7 1.3 6.7M20 15h-1a4 4 0 0 0-4 4v1M8.6 4c0 .8.1 1.9 1.5 2.6 1.4.7 3 .3 3 2.3 0 .3 0 2 1.9 2 2 0 2-1.7 2-2 0-.6.5-.9 1.2-.9H20m1 4a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
    //         stroke="#888888"
    //         strokeLinecap="round"
    //         strokeLinejoin="round"
    //       />

    //     </svg>
    //   ),
    // },
  ];
  const sideBarDataCustomer = [
    {
      label: "Profile",
      path: `/customers/add-customer?tab=0&id=${loggedInUser.userId}`,
      icon: (
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.986 14.0673C7.4407 14.0673 4.41309 14.6034 4.41309 16.7501C4.41309 18.8969 7.4215 19.4521 10.986 19.4521C14.5313 19.4521 17.5581 18.9152 17.5581 16.7693C17.5581 14.6234 14.5505 14.0673 10.986 14.0673Z"
            stroke="#888888"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.986 11.0054C13.3126 11.0054 15.1983 9.11881 15.1983 6.79223C15.1983 4.46564 13.3126 2.57993 10.986 2.57993C8.65944 2.57993 6.77285 4.46564 6.77285 6.79223C6.76499 9.11096 8.63849 10.9975 10.9563 11.0054H10.986Z"
            stroke="#888888"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },

    {
      label: "Map",
      path: `/map?id=${loggedInUser.userId}`,
      icon: <LocationOnOutlinedIcon sx={{ color: "#888888", fontSize: 20 }} />,
    },
  ];
  const [subMenu, setSubMenu] = useState([
    {
      label: "Monthly",
      path: "/summary-report",
    },
    {
      label: "Monthly Landsacpe",
      path: "/landscape",
    },
    // {
    //   label: "Safety Reports",
    //   path: `/safety-reports/list`,
    // },
    // {
    //   label: "Proposal Summary",
    //   path: "/proposal-summary",
    // },
    {
      label: "Weekly Landsacpe",
      path: "/weekly-reports",
    },
    {
      label: "Monthly Reports - RC",
      path: "/Weekly-Reports/rising-canes",
    },
  ]);

  const irrMenu = [
    {
      label: "Irrigation Audit",
      path:
        loggedInUser.userRole == "2"
          ? `/Irrigation?CustomerId=${loggedInUser.userId}`
          : `/Irrigation`,
    },
    {
      label: "Controller Audit",
      path:
        loggedInUser.userRole == "2"
          ? `/irrigation-audit?CustomerId=${loggedInUser.userId}`
          : `/irrigation-audit`,
    },
  ];

  const pLMenu = [
    {
      label: "PunchList",
      path:
        loggedInUser.userRole == "2"
          ? `/punchlist?CustomerId=${loggedInUser.userId}`
          : `/punchlist`,
    },
    {
      label: "PunchList Photos Only",
      path:
        loggedInUser.userRole == "2"
          ? `/punchList-photos-only?CustomerId=${loggedInUser.userId}`
          : `/punchList-photos-only`,
    },
  ];

  useEffect(() => {
    if (Cookies.get("CompanyId") == 1) {
      setSideBarDataRM([
        {
          label: "Dashboard",
          path: "/dashboard",
          icon: (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.5 7.49999L10 1.66666L17.5 7.49999V16.6667C17.5 17.1087 17.3244 17.5326 17.0118 17.8452C16.6993 18.1577 16.2754 18.3333 15.8333 18.3333H4.16667C3.72464 18.3333 3.30072 18.1577 2.98816 17.8452C2.67559 17.5326 2.5 17.1087 2.5 16.6667V7.49999Z"
                stroke="#888888"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.5 18.3333V10H12.5V18.3333"
                stroke="#888888"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ),
        },
        // {
        //   label: "KPIs",
        //   path: "/kpi-dashboard",
        //   icon: <GrDocumentPerformance size={"20px"} color="#8c8b8b"/>,
        // },
        {
          label: "Customers",
          path: "/customers",
          icon: (
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.986 14.0673C7.4407 14.0673 4.41309 14.6034 4.41309 16.7501C4.41309 18.8969 7.4215 19.4521 10.986 19.4521C14.5313 19.4521 17.5581 18.9152 17.5581 16.7693C17.5581 14.6234 14.5505 14.0673 10.986 14.0673Z"
                stroke="#888888"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.986 11.0054C13.3126 11.0054 15.1983 9.11881 15.1983 6.79223C15.1983 4.46564 13.3126 2.57993 10.986 2.57993C8.65944 2.57993 6.77285 4.46564 6.77285 6.79223C6.76499 9.11096 8.63849 10.9975 10.9563 11.0054H10.986Z"
                stroke="#888888"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ),
        },

        {
          label: "Map",
          path: "/map",
          icon: (
            <LocationOnOutlinedIcon sx={{ color: "#888888", fontSize: 20 }} />
          ),
        },
        {
          label: "Service Requests",
          path: "/service-requests",
          icon: (
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 7V6c0-.6.4-1 1-1h11c.6 0 1 .4 1 1v7c0 .6-.4 1-1 1h-1M3 18v-7c0-.6.4-1 1-1h11c.6 0 1 .4 1 1v7c0 .6-.4 1-1 1H4a1 1 0 0 1-1-1Zm8-3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
                stroke="#888888"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ),
        },
        {
          label: "Estimates",
          path: "/estimates",
          icon: (
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.8381 12.7317C16.4566 12.7317 16.9757 13.2422 16.8811 13.853C16.3263 17.4463 13.2502 20.1143 9.54009 20.1143C5.43536 20.1143 2.10834 16.7873 2.10834 12.6835C2.10834 9.30245 4.67693 6.15297 7.56878 5.44087C8.19018 5.28745 8.82702 5.72455 8.82702 6.36429C8.82702 10.6987 8.97272 11.8199 9.79579 12.4297C10.6189 13.0396 11.5867 12.7317 15.8381 12.7317Z"
                stroke="#888888"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.8848 9.1223C19.934 6.33756 16.5134 1.84879 12.345 1.92599C12.0208 1.93178 11.7612 2.20195 11.7468 2.5252C11.6416 4.81493 11.7834 7.78204 11.8626 9.12713C11.8867 9.5459 12.2157 9.87493 12.6335 9.89906C14.0162 9.97818 17.0914 10.0862 19.3483 9.74467C19.6552 9.69835 19.88 9.43204 19.8848 9.1223Z"
                stroke="#888888"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ),
        },
        {
          label: "Purchase Order",
          path: "/purchase-order",
          icon: (
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.4065 14.8714H7.78821"
                stroke="#888888"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M14.4065 11.0338H7.78821"
                stroke="#888888"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M10.3137 7.2051H7.78827"
                stroke="#888888"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.5829 2.52066C14.5829 2.52066 7.54563 2.52433 7.53463 2.52433C5.00463 2.53991 3.43805 4.20458 3.43805 6.74374V15.1734C3.43805 17.7254 5.01655 19.3965 7.56855 19.3965C7.56855 19.3965 14.6049 19.3937 14.6168 19.3937C17.1468 19.3782 18.7143 17.7126 18.7143 15.1734V6.74374C18.7143 4.19174 17.1349 2.52066 14.5829 2.52066Z"
                stroke="#888888"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
          ),
        },

        {
          label: "Items",
          path: "/items",
          icon: (
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.1 4H5c-.5 0-.9.4-.9.9V9c0 .5.4.9.9.9h4c.5 0 .9-.4.9-.9V5c0-.5-.4-.9-.9-.9Zm10 0H15c-.5 0-.9.4-.9.9V9c0 .5.4.9.9.9h4c.5 0 .9-.4.9-.9V5c0-.5-.4-.9-.9-.9Zm-10 10H5c-.5 0-.9.4-.9.9V19c0 .5.4.9.9.9h4c.5 0 .9-.4.9-.9v-4c0-.5-.4-.9-.9-.9Zm10 0H15c-.5 0-.9.4-.9.9V19c0 .5.4.9.9.9h4c.5 0 .9-.4.9-.9v-4c0-.5-.4-.9-.9-.9Z"
                stroke="#888888"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ),
        },
        {
          label: "Wages",
          path: "/wages",
          icon: <TaskIcon sx={{ color: "#888888", fontSize: 20 }} />,
        },
      ]);
    }

    console.log("asdasdasodkasp", Cookies.get("CompanyId"));

    const handleClickOutside = (event) => {
      if (subShowRef.current && !subShowRef.current.contains(event.target)) {
        setSubHov(false);
      }
      if (irrShowRef.current && !irrShowRef.current.contains(event.target)) {
        setSubHov(false);
      }
      if (pLShowRef.current && !pLShowRef.current.contains(event.target)) {
        setSubHov(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    const handleSidebar = (event) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target) &&
        !eliminate.current.contains(event.target) &&
        window.innerWidth < 450
      ) {
        setMainControl("mobileH");
      }
    };

    document.body.addEventListener("click", handleSidebar);

    if (Cookies.get("userRole") == 2) {
      setSubMenu([
        {
          label: "Monthly",
          path: "/summary-report",
        },
        {
          label: "Monthly Landsacpe",
          path: `/landscape?CustomerId=${loggedInUser.userId}`,
        },
       
      ]);
    }
    if (Cookies.get("userRole") == 1) {
      setSubMenu([
        {
          label: "Monthly",
          path: "/summary-report",
        },
        {
          label: "Monthly Landsacpe",
          path: "/landscape",
        },
        // {
        //   label: "Safety Reports",
        //   path: `/safety-reports/list`,
        // },

        {
          label: "Weekly Landsacpe",
          path: "/weekly-reports",
        },
        {
          label: "Monthly Reports - RC",
          path: "/Weekly-Reports/rising-canes",
        },
        {
          label: "Aging Report",
          path: "/aging-report",
        },
      ]);
    }

    return () => {
      document.body.removeEventListener("click", handleSidebar);
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handlePreventLink = (event) => {
    event.preventDefault();
    if (mainControl === "tab") {
      setSubHov(!showSubMenuHov);
      setShowSM(false);
    } else {
      // toggleShowMenu();
      setShowSM(!showSubMenu);
    }
  };

  const handleIrrLink = (event) => {
    event.preventDefault();
    if (mainControl === "tab") {
      setSubHov(!showSubMenuHov);
      setShowIrrMenu(false);
    } else {
      // toggleShowMenu();
      setShowIrrMenu(!showIrrMenu);
    }
  };

  const handlePlLink = (event) => {
    event.preventDefault();
    if (mainControl === "tab") {
      setSubHov(!showSubMenuHov);
      setShowPlMenu(false);
    } else {
      // toggleShowMenu();
      setShowPlMenu(!showPlMenu);
    }
  };

  let activeClass = "";

  const renderOptions = sideBarData.map((option, index) => {
    const handleActiveClass = (clickIndex) => {
      setActiveIndex(clickIndex);
    };
    if (activeIndex === index) {
      activeClass = "mm-active";
    }
    if (window.location.pathname.includes(option.path.split("?")[0])) {
      activeClass = "mm-active";
    } else {
      activeClass = "";
    }
    return (
      <li
        key={index}
        className={activeClass + " linkSide"}
        onClick={() => {
          handleActiveClass(index);
        }}
      >
        <NavLink to={option.path}>
          <div className="menu-icon ">{option.icon}</div>
          <span className="nav-text navLabel">{option.label}</span>
        </NavLink>
      </li>
    );
  });

  const renderOptionsRM = sideBarDataRM.map((option, index) => {
    const handleActiveClass = (clickIndex) => {
      setActiveIndex(clickIndex);
    };
    if (activeIndex === index) {
      activeClass = "mm-active";
    }
    if (window.location.pathname.includes(option.path.split("?")[0])) {
      activeClass = "mm-active";
    } else {
      activeClass = "";
    }
    return (
      <li
        key={index}
        className={activeClass + " linkSide"}
        onClick={() => {
          handleActiveClass(index);
        }}
      >
        <NavLink to={option.path}>
          <div className="menu-icon ">{option.icon}</div>
          <span className="nav-text navLabel">{option.label}</span>
        </NavLink>
      </li>
    );
  });
  const renderOptionsIrr = sideBarDataIrr.map((option, index) => {
    const handleActiveClass = (clickIndex) => {
      setActiveIndex(clickIndex);
    };
    if (activeIndex === index) {
      activeClass = "mm-active";
    }
    if (window.location.pathname.includes(option.path.split("?")[0])) {
      activeClass = "mm-active";
    } else {
      activeClass = "";
    }
    return (
      <li
        key={index}
        className={activeClass + " linkSide"}
        onClick={() => {
          handleActiveClass(index);
        }}
      >
        <NavLink to={option.path}>
          <div className="menu-icon ">{option.icon}</div>
          <span className="nav-text navLabel">{option.label}</span>
        </NavLink>
      </li>
    );
  });
  const renderOptionsCustomer = sideBarDataCustomer.map((option, index) => {
    const handleActiveClass = (clickIndex) => {
      setActiveIndex(clickIndex);
    };
    if (activeIndex === index) {
      activeClass = "mm-active";
    }
    if (window.location.pathname.includes(option.path.split("?")[0])) {
      activeClass = "mm-active";
    } else {
      activeClass = "";
    }
    return (
      <li
        key={index}
        className={activeClass + " linkSide"}
        onClick={() => {
          handleActiveClass(index);
        }}
      >
        <NavLink to={option.path}>
          <div className="menu-icon ">{option.icon}</div>
          <span className="nav-text navLabel">{option.label}</span>
        </NavLink>
      </li>
    );
  });
  const toggleShowMenu = () => {
    setShowSM(!showSubMenu);
  };
  useEffect(() => {
    const bottomChev = document.getElementById("bottomChev");
    const irrbottomChev = document.getElementById("irrbottomChev");
    const plbottomChev = document.getElementById("plbottomChev");

    if (bottomChev) {
      if (showSubMenu === true) {
        bottomChev.classList?.add("rotatezero");
      } else {
        bottomChev.classList?.remove("rotatezero");
      }
    }

    if (irrbottomChev) {
      if (showIrrMenu === true) {
        irrbottomChev.classList?.add("rotatezero");
      } else {
        irrbottomChev.classList?.remove("rotatezero");
      }
    }

    if (plbottomChev) {
      if (showPlMenu === true) {
        plbottomChev.classList?.add("rotatezero");
      } else {
        plbottomChev.classList?.remove("rotatezero");
      }
    }
  }, [showSubMenu, showIrrMenu, showPlMenu]);

  return (
    <>
      <div className="deznav" id="sideBarDez" ref={sidebarRef}>
        <div className="deznav-scroll">
          <ul className="metismenu" id="menu">
            {loggedInUser.userRole == 1
              ? renderOptions
              : loggedInUser.userRole == 5 || loggedInUser.userRole == 6
              ? renderOptionsIrr
              : loggedInUser.userRole == 2
              ? renderOptionsCustomer
              : renderOptionsRM}
            <Authorization allowTo={[1, 4, 5]} hide>
              <li>
                <a
                  href="/"
                  className="expand-bottom"
                  onClick={handleIrrLink}
                  ref={irrShowRef}
                >
                  <div className="menu-icon">
                    <svg
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.5346 2.55658H7.1072C4.28845 2.55658 2.52112 4.55216 2.52112 7.37733V14.9985C2.52112 17.8237 4.2802 19.8192 7.1072 19.8192H15.1959C18.0238 19.8192 19.7829 17.8237 19.7829 14.9985V11.3062"
                        stroke="#888888"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.09214 10.0108L14.9424 3.16057C15.7958 2.30807 17.1791 2.30807 18.0325 3.16057L19.1481 4.27615C20.0015 5.12957 20.0015 6.51374 19.1481 7.36624L12.2648 14.2495C11.8917 14.6226 11.3857 14.8325 10.8577 14.8325H7.42389L7.51006 11.3675C7.52289 10.8578 7.73097 10.372 8.09214 10.0108Z"
                        stroke="#888888"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.9014 4.21895L18.0869 8.40445"
                        stroke="#888888"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <span className="nav-text navLabel">
                    Irrigation
                    <span
                      className="material-symbols-sharp"
                      style={{ top: "6px" }}
                      id="irrbottomChev"
                    >
                      <ExpandMoreOutlinedIcon
                        sx={{ color: "#888888", fontSize: 20 }}
                      />
                    </span>
                  </span>
                </a>

                {showIrrMenu && (
                  <ul className="subMenu">
                    <>
                      {irrMenu.map((link, index) => {
                        let activeClass = "";
                        if (
                          window.location.pathname.includes(
                            link.path.split("?")[0]
                          )
                        ) {
                          activeClass = "activeSub";
                        } else {
                          activeClass = "";
                        }

                        return (
                          <li key={index}>
                            <NavLink
                              to={link.path}
                              style={{
                                display: "flex",
                                color: window.location.pathname.includes(
                                  link.path.split("?")[0]
                                )
                                  ? "#77993d"
                                  : "#c3c1c1",
                              }}
                              onMouseEnter={() => setSubClass(index)}
                              onMouseLeave={() => setSubClass(-1)}
                            >
                              <div className="blueBarBox">
                                <span
                                  id="blueBar"
                                  className={activeClass}
                                ></span>
                              </div>
                              {link.label}
                            </NavLink>
                          </li>
                        );
                      })}
                    </>
                  </ul>
                )}
              </li>
            </Authorization>
            {loggedInUser.userRole == 5 || loggedInUser.userRole == 6 ? (
              <></>
            ) : (
              <>
                <li>
                  <a
                    href="/"
                    className="expand-bottom"
                    onClick={handlePlLink}
                    ref={pLShowRef}
                  >
                    <div className="menu-icon">
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M18.634 13.4211C18.634 16.7009 16.7007 18.6342 13.4209 18.6342H6.28738C2.99929 18.6342 1.06238 16.7009 1.06238 13.4211V6.27109C1.06238 2.99584 2.26688 1.06259 5.54763 1.06259H7.38096C8.03913 1.06351 8.65879 1.37242 9.05296 1.89951L9.88988 3.01234C10.2859 3.53851 10.9055 3.84834 11.5637 3.84926H14.1579C17.446 3.84926 18.6596 5.52309 18.6596 8.86984L18.634 13.4211Z"
                          stroke="#888888"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                        <path
                          d="M5.85754 12.2577H13.8646"
                          stroke="#888888"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </div>
                    <span className="nav-text navLabel">
                      PunchList
                      <span
                        className="material-symbols-sharp"
                        style={{ top: "6px" }}
                        id="plbottomChev"
                      >
                        <ExpandMoreOutlinedIcon
                          sx={{ color: "#888888", fontSize: 20 }}
                        />
                      </span>
                    </span>
                  </a>
                  {showPlMenu && (
                    <ul className="subMenu">
                      <>
                        {pLMenu.map((link, index) => {
                          let activeClass = "";
                          if (
                            window.location.pathname.includes(
                              link.path.split("?")[0]
                            )
                          ) {
                            activeClass = "activeSub";
                          } else {
                            activeClass = "";
                          }
                          // const activeSub = (indx) => {
                          //     if (indx === indx) {
                          //         setSubClass('activeSub')
                          //     }
                          // }
                          // const deactiveSub = () => {
                          //     setSubClass('')
                          // }

                          return (
                            <li key={index}>
                              <NavLink
                                to={link.path}
                                style={{
                                  display: "flex",
                                  color: window.location.pathname.includes(
                                    link.path.split("?")[0]
                                  )
                                    ? "#77993d"
                                    : "#c3c1c1",
                                }}
                                onMouseEnter={() => setSubClass(index)}
                                onMouseLeave={() => setSubClass(-1)}
                              >
                                <div className="blueBarBox">
                                  <span
                                    id="blueBar"
                                    className={activeClass}
                                  ></span>
                                </div>
                                {link.label}
                              </NavLink>
                            </li>
                          );
                        })}
                      </>
                    </ul>
                  )}
                </li>

                <li>
                  <a
                    href="/"
                    className="expand-bottom"
                    onClick={handlePreventLink}
                    ref={subShowRef}
                  >
                    <div className="menu-icon">
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.75713 9.35157V15.64"
                          stroke="#888888"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                        <path
                          d="M11.0349 6.34253V15.64"
                          stroke="#888888"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                        <path
                          d="M15.2428 12.6746V15.64"
                          stroke="#888888"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M15.2952 1.83333H6.70474C3.7103 1.83333 1.83331 3.95274 1.83331 6.95306V15.0469C1.83331 18.0473 3.70157 20.1667 6.70474 20.1667H15.2952C18.2984 20.1667 20.1666 18.0473 20.1666 15.0469V6.95306C20.1666 3.95274 18.2984 1.83333 15.2952 1.83333Z"
                          stroke="#888888"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                    </div>
                    <span className="nav-text navLabel">
                      Reports
                      <span
                        className="material-symbols-sharp"
                        style={{ top: "6px" }}
                        id="bottomChev"
                      >
                        <ExpandMoreOutlinedIcon
                          sx={{ color: "#888888", fontSize: 20 }}
                        />
                      </span>
                    </span>
                  </a>
                  {showSubMenu && (
                    <ul className="subMenu">
                      <>
                        {subMenu.map((link, index) => {
                          let activeClass = "";
                          if (
                            window.location.pathname.includes(
                              link.path.split("?")[0]
                            )
                          ) {
                            activeClass = "activeSub";
                          } else {
                            activeClass = "";
                          }
                          // const activeSub = (indx) => {
                          //     if (indx === indx) {
                          //         setSubClass('activeSub')
                          //     }
                          // }
                          // const deactiveSub = () => {
                          //     setSubClass('')
                          // }

                          return (
                            <li key={index}>
                              <NavLink
                                to={link.path}
                                style={{
                                  display: "flex",
                                  color: window.location.pathname.includes(
                                    link.path.split("?")[0]
                                  )
                                    ? "#77993d"
                                    : "#c3c1c1",
                                }}
                                onMouseEnter={() => setSubClass(index)}
                                onMouseLeave={() => setSubClass(-1)}
                              >
                                <div className="blueBarBox">
                                  <span
                                    id="blueBar"
                                    className={activeClass}
                                  ></span>
                                </div>
                                {link.label}
                              </NavLink>
                            </li>
                          );
                        })}
                      </>
                    </ul>
                  )}
                </li>
              </>
            )}
          </ul>
        </div>
      </div>

      {showSubMenuHov && (
        <ul className="activeHover subMenu">
          {showSubMenuHov && (
            <>
              {subMenu.map((link, index) => {
                let activeClass = "";
                if (
                  window.location.pathname.includes(link.path.split("?")[0])
                ) {
                  activeClass = "activeSub";
                } else {
                  activeClass = "";
                }

                return (
                  <li key={index}>
                    <NavLink
                      to={link.path}
                      style={{
                        display: "flex",
                        color: window.location.pathname.includes(
                          link.path.split("?")[0]
                        )
                          ? "#77993d"
                          : "#c3c1c1",
                      }}
                      onMouseEnter={() => setSubClass(index)}
                      onMouseLeave={() => setSubClass(-1)}
                    >
                      <div className="blueBarBox">
                        <span id="blueBar" className={activeClass}></span>
                      </div>
                      {link.label}
                    </NavLink>
                  </li>
                );
              })}
            </>
          )}
        </ul>
      )}
    </>
  );
};

export default SideBar;
